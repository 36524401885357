// @ts-nocheck
http.displayName = 'http'
http.aliases = []

/** @type {import('../core.js').Syntax} */
export default function http(Prism) {
  ;(function (Prism) {
    /**
     * @param {string} name
     * @returns {RegExp}
     */
    function headerValueOf(name) {
      return RegExp('(^(?:' + name + '):[ \t]*(?![ \t]))[^]+', 'i')
    }
    Prism.languages.http = {
      'request-line': {
        pattern:
          /^(?:CONNECT|DELETE|GET|HEAD|OPTIONS|PATCH|POST|PRI|PUT|SEARCH|TRACE)\s(?:https?:\/\/|\/)\S*\sHTTP\/[\d.]+/m,
        inside: {
          // HTTP Method
          method: {
            pattern: /^[A-Z]+\b/,
            alias: 'property'
          },
          // Request Target e.g. http://example.com, /path/to/file
          'request-target': {
            pattern: /^(\s)(?:https?:\/\/|\/)\S*(?=\s)/,
            lookbehind: true,
            alias: 'url',
            inside: Prism.languages.uri
          },
          // HTTP Version
          'http-version': {
            pattern: /^(\s)HTTP\/[\d.]+/,
            lookbehind: true,
            alias: 'property'
          }
        }
      },
      'response-status': {
        pattern: /^HTTP\/[\d.]+ \d+ .+/m,
        inside: {
          // HTTP Version
          'http-version': {
            pattern: /^HTTP\/[\d.]+/,
            alias: 'property'
          },
          // Status Code
          'status-code': {
            pattern: /^(\s)\d+(?=\s)/,
            lookbehind: true,
            alias: 'number'
          },
          // Reason Phrase
          'reason-phrase': {
            pattern: /^(\s).+/,
            lookbehind: true,
            alias: 'string'
          }
        }
      },
      header: {
        pattern: /^[\w-]+:.+(?:(?:\r\n?|\n)[ \t].+)*/m,
        inside: {
          'header-value': [
            {
              pattern: headerValueOf(/Content-Security-Policy/.source),
              lookbehind: true,
              alias: ['csp', 'languages-csp'],
              inside: Prism.languages.csp
            },
            {
              pattern: headerValueOf(/Public-Key-Pins(?:-Report-Only)?/.source),
              lookbehind: true,
              alias: ['hpkp', 'languages-hpkp'],
              inside: Prism.languages.hpkp
            },
            {
              pattern: headerValueOf(/Strict-Transport-Security/.source),
              lookbehind: true,
              alias: ['hsts', 'languages-hsts'],
              inside: Prism.languages.hsts
            },
            {
              pattern: headerValueOf(/[^:]+/.source),
              lookbehind: true
            }
          ],
          'header-name': {
            pattern: /^[^:]+/,
            alias: 'keyword'
          },
          punctuation: /^:/
        }
      }
    } // Create a mapping of Content-Type headers to language definitions
    var langs = Prism.languages
    var httpLanguages = {
      'application/javascript': langs.javascript,
      'application/json': langs.json || langs.javascript,
      'application/xml': langs.xml,
      'text/xml': langs.xml,
      'text/html': langs.html,
      'text/css': langs.css,
      'text/plain': langs.plain
    } // Declare which types can also be suffixes
    var suffixTypes = {
      'application/json': true,
      'application/xml': true
    }
    /**
     * Returns a pattern for the given content type which matches it and any type which has it as a suffix.
     *
     * @param {string} contentType
     * @returns {string}
     */
    function getSuffixPattern(contentType) {
      var suffix = contentType.replace(/^[a-z]+\//, '')
      var suffixPattern = '\\w+/(?:[\\w.-]+\\+)+' + suffix + '(?![+\\w.-])'
      return '(?:' + contentType + '|' + suffixPattern + ')'
    } // Insert each content type parser that has its associated language
    // currently loaded.
    var options
    for (var contentType in httpLanguages) {
      if (httpLanguages[contentType]) {
        options = options || {}
        var pattern = suffixTypes[contentType]
          ? getSuffixPattern(contentType)
          : contentType
        options[contentType.replace(/\//g, '-')] = {
          pattern: RegExp(
            '(' +
              /content-type:\s*/.source +
              pattern +
              /(?:(?:\r\n?|\n)[\w-].*)*(?:\r(?:\n|(?!\n))|\n)/.source +
              ')' + // This is a little interesting:
              // The HTTP format spec required 1 empty line before the body to make everything unambiguous.
              // However, when writing code by hand (e.g. to display on a website) people can forget about this,
              // so we want to be liberal here. We will allow the empty line to be omitted if the first line of
              // the body does not start with a [\w-] character (as headers do).
              /[^ \t\w-][\s\S]*/.source,
            'i'
          ),
          lookbehind: true,
          inside: httpLanguages[contentType]
        }
      }
    }
    if (options) {
      Prism.languages.insertBefore('http', 'header', options)
    }
  })(Prism)
}

import React from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import { Authenticator, buildCollection, FirebaseCMSApp, NavigationBuilder, NavigationBuilderProps } from '@camberi/firecms';
import 'typeface-rubik';
import 'typeface-space-mono';
import './styles.css';
import { contestSchema } from './schemas/Contest.schema';
import { prizeSchema } from './schemas/Prize.schema';
import icon from './assets/icon.png';
import { User, Role } from './model/User';
import { userSchema } from './schemas/User.schema';
import { clientSchema } from './schemas/Client.schema';
import { awardedSchema } from './schemas/Awarded.schema';
import { prizeCallbacks } from './callbacks/Prize.callbacks';

const firebaseConfig = {
  apiKey: "AIzaSyA_NY0CpGL7wAwGx9FGmuokuwa6O6pbBDY",
  authDomain: "firecms-brx-test.firebaseapp.com",
  projectId: "firecms-brx-test",
  storageBucket: "firecms-brx-test.appspot.com",
  messagingSenderId: "501072916989",
  appId: "1:501072916989:web:89b230197c1bb243cbe994"
};

export default function App() {
  const navigation: NavigationBuilder = async ({ authController }: NavigationBuilderProps) => {
    const role = authController.extra.role;
    const client = authController.extra.client;
    console.log('client', client);
    const collections: any[] = [];
    if(role === Role.admin) {
      collections.push(buildCollection({
        path: 'users',
        schema: userSchema,
        name: 'Usuarios'
      }));
    }
    collections.push(buildCollection({
      path: 'clients',
      schema: clientSchema,
      name: 'Clientes',
      initialFilter: role === Role.user ? { name: ['==', client.name] } : {},
      permissions: ({ authController }) => { 
        return {
          edit: role === Role.admin,
          create: role === Role.admin,
          delete: role === Role.admin
      }},
      subcollections: [
        buildCollection({
          path: 'contests',
          schema: contestSchema,
          name: 'Concursos',
          permissions: ({ authController }) => ({
            edit: role === Role.admin || role === Role.user,
            create: role === Role.admin || role === Role.user,
            delete: role === Role.admin
          }),
          subcollections: [
            buildCollection({
              name: 'Premios',
              path: 'prizes',
              schema: prizeSchema,
              callbacks: prizeCallbacks,
              permissions: ({ authController }) => ({
                edit: role === Role.admin || role === Role.user,
                create: role === Role.admin || role === Role.user,
                delete: role === Role.admin || role === Role.user
              }),
            }),
            buildCollection({
              name: 'Premiados',
              path: 'awarded',
              schema: awardedSchema,
              permissions: ({ authController }) => ({
                edit: role === Role.admin || role === Role.user,
                create: false,
                delete: false
              }),
            })
          ]
        })
      ]
    }));
    return ({
      collections: collections
    });
  };
  const authenticator: Authenticator<FirebaseUser> = async ({ user, authController, dataSource }) => {
    // You can throw an error to display a message
    if(user?.email?.includes('flanders')){
      throw Error('Stupid Flanders!');
    }
    console.log('Allowing access to', user?.email);
    const users = await dataSource.fetchCollection<User>({ path: 'users', schema: userSchema, filter: { 'email': ['==', user.email] }});
    // set user information
    if(users.length) {
      const u = users[0].values;
      if(u.role === Role.user) {
        // fetch client
        console.log('fetch client');
        const c = await dataSource.fetchEntity({ path: u.client.path, entityId: u.client.id, schema: clientSchema });
        authController.setExtra({ ...u, client: c.values });
      }
      else {
        authController.setExtra(u);
      }
    }
    else {
      // TODO: remove this. Just for testing purposes
      authController.setExtra({ role: Role.admin });
    }
    return true;
  };
  return <FirebaseCMSApp 
    name={'CMS'}
    signInOptions={['password']}
    authentication={authenticator}
    navigation={navigation}
    firebaseConfig={firebaseConfig}
    logo={icon}
    locale={'enAU'}
    dateTimeFormat={'yy/MM/dd HH:mm:ss'}
    fontFamily={'Circular Std Medium, sans serif'}
  />;
}
import { buildEntityCallbacks, EntityOnSaveProps, Entity } from '@camberi/firecms';
import Prize from '../model/Prize';

export const prizeCallbacks = buildEntityCallbacks({
  onPreSave: (props: EntityOnSaveProps<Prize>) => {
    if(props.values.empty) {
      props.values.dailyDispatch = -1;
      props.values.totalStock = -1;
    }
    return props.values;
  },
  onSaveSuccess: async (props: EntityOnSaveProps<Prize>) => {
    // check if daily dispatch changed for probability prizes or probability changed for empty prizes
    if(!props.previousValues || props.values.dailyDispatch !== props.previousValues.dailyDispatch || (props.values.empty && props.values.probability !== props.previousValues.probability)) {
      console.log('Setting probabilities');
      let dailyStock = 0;
      let prizeProbability = 1;
      const probabilityPrizes: Entity<Prize>[] = [];
      // fetch all prizes
      const prizes = await props.context.dataSource.fetchCollection({ path: props.path, schema: props.schema });
      prizes.forEach(doc => {
        if(!doc.values.winnerMoment) {
          if(doc.values.empty) {
            prizeProbability -= doc.values.probability;
          }
          else {
            dailyStock += doc.values.dailyDispatch;
            probabilityPrizes.push(doc);
          }
        }
      });
      probabilityPrizes.forEach(async doc => {
        doc.values.probability = doc.values.dailyDispatch / dailyStock * prizeProbability;
        await props.context.dataSource.saveEntity({ path: doc.path, entityId: doc.id, values: doc.values, schema: props.schema, status: 'existing' });
      });
    }
  }
});
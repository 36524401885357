import React, { ReactElement } from 'react';
import { FieldProps } from '@camberi/firecms';
import { Button } from '@mui/material';

const ReloadPreview = ({ property, value, setValue, customProps, touched, error, isSubmitting, context, ...props }: FieldProps<boolean>): ReactElement => {
  console.log('context', context);
  console.log('customProps', customProps);

  const onReload = () => {
    console.log(`/manualReload?clientId=${customProps.client}&contestId=${context.entityId}`);
    fetch(`https://us-central1-firecms-brx-test.cloudfunctions.net/manualReload?clientId=${customProps.client}&contestId=${context.entityId}`).then(() => console.log('Contest reloaded'));
  }

  return (
    <Button variant="contained" color="error" onClick={onReload}>Recargar</Button>
  );
}

export default ReloadPreview;
import { buildSchema, buildProperty } from '@camberi/firecms';
import Contest from '../model/Contest';
import ReloadPreview from '../previews/Reload.preview';

export const contestSchema = buildSchema<Contest & { reload: boolean } >({
  name: 'Concurso',
  properties: {
    reload: ({ entityId, path }) => buildProperty({
      dataType: 'boolean',
      config: {
        Field: ReloadPreview,
        customProps: {
          client: path.split('/')[1]
        }
      },
      disabled: !entityId ? { hidden: true } : false
    }),
    name: {
      title: 'Nombre',
      dataType: 'string',
      validation: { 
        required: true,
        max: 30 
      }
    },
    dataPickup: {
      title: 'Recogida de datos',
      dataType: 'boolean'
    },
    maximizePrizes: {
      title: 'Maximizar entrega de premios',
      dataType: 'boolean',
      columnWidth: 200
    },
    dates: {
      title: 'Fechas',
      dataType: 'array',
      validation: { required: true },
      of: {
        dataType: 'timestamp'
      }
    }
  }
});
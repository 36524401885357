import { buildSchema, buildProperty } from '@camberi/firecms';
import { Role, User } from '../model/User';

export const userSchema = buildSchema<User>({
  name: 'Usuario',
  properties: {
    firstName: {
      title: 'Nombre',
      dataType: 'string',
      validation: { 
        required: true,
        max: 50
      }
    },
    lastName: {
      title: 'Apellido',
      dataType: 'string',
      validation: { 
        required: true,
        max: 50
      }
    },
    email: {
      title: 'Email',
      dataType: 'string',
      validation: { 
        required: true,
        unique: true,
        email: true,
        max: 100
      }
    },
    role: buildProperty({
      title: 'Rol',
      dataType: 'string',
      config: {
        enumValues: {
          'admin': 'admin',
          'user': 'user'
        }
      },
      validation: { required: true }
    }),
    client: ({ values }) => buildProperty({
      title: 'Cliente',
      dataType: 'reference',
      path: 'clients',
      disabled: values.role === Role.admin && {
        clearOnDisabled: true,
        hidden: true
      },
      validation: {
        required: values.role === Role.user
      }
    })
  },
  defaultValues: {
    role: Role.user
  }
});
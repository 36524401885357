import { buildSchema, buildProperty } from '@camberi/firecms';
import Awarded from '../model/Awarded';

export const awardedSchema = buildSchema<Awarded>({
  name: 'Premiado',
  properties: {
    code: {
      title: 'Código',
      dataType: 'string',
      disabled: true
    },
    collected: {
      title: 'Entregado',
      dataType: 'boolean'
    },
    name: {
      title: 'Nombre',
      dataType: 'string',
      disabled: true
    },
    SKU: {
      title: 'SKU',
      dataType: 'string',
      disabled: true
    },
    timestamp: {
      title: 'Timestamp',
      dataType: 'string',
      disabled: true
    },
    collectedBy: {
      title: 'Entregado a',
      dataType: 'string'
    }
  }
});
import { EntityReference } from "@camberi/firecms";

export type User = {
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  client: EntityReference
}

export enum Role {
  admin = 'admin',
  user = 'user'
}
import { buildSchema } from '@camberi/firecms';
import Client from '../model/Client';

export const clientSchema = buildSchema<Client>({
  name: 'Cliente',
  properties: {
    name: {
      title: 'Nombre',
      dataType: 'string',
      validation: { 
        required: true,
        unique: true,
        max: 50
      }
    },
    description: {
      title: 'Descripción',
      dataType: 'string',
      validation: { max: 200 }
    }
  }
});
import { buildSchema, buildProperty } from '@camberi/firecms';
import Prize from '../model/Prize';

export const prizeSchema = buildSchema<Prize>({
  name: 'Premio',
  properties: {
    name: {
      title: 'Nombre',
      dataType: 'string',
      validation: { 
        required: true,
        max: 30
      }
    },
    sku: {
      title: 'SKU',
      dataType: 'string',
      validation: { 
        required: true,
        max: 50
      }
    },
    winnerMoment: ({ entityId }) => buildProperty({
      title: 'Momento premiado',
      dataType: 'boolean',
      disabled: entityId ? true : false
    }),
    empty: ({ values }) => buildProperty({
      title: 'Casilla no premiada',
      dataType: 'boolean',
      disabled: values.winnerMoment && {
        clearOnDisabled: true,
        hidden: true
      }
    }),
    totalStock: ({ values }) => buildProperty({
      title: 'Stock total',
      dataType: 'number',
      validation: {
        required: !values.empty,
        integer: true,
        min: values.empty ? -1 : 0
      },
      disabled: values.empty && {
        hidden: true
      }
    }),
    dailyDispatch: ({ values }) => buildProperty({
      title: 'Entrega diaria',
      dataType: 'number',
      validation: {
        required: !values.empty,
        integer: true,
        min: values.empty ? -1 : 0
      },
      disabled: values.winnerMoment || values.empty && {
        hidden: true
      }
    }),
    probability: ({ values }) => buildProperty({
      title: 'Probabilidad',
      dataType: 'number',
      validation: {
        // required: !values.winnerMoment && values.empty,
        min: 0,
        max: 1
      },
      disabled: values.winnerMoment || !values.empty && {
        clearOnDisabled: values.winnerMoment,
        hidden: values.winnerMoment
      }
    }),
    icon: buildProperty({
      title: 'Icon',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images/prizes',
          acceptedFiles: ['image/*']
        }
      }
    }),
    dates: ({ values }) => buildProperty({
      title: 'Fechas',
      dataType: 'array',
      of: {
        dataType: 'timestamp'
      },
      disabled: !values.winnerMoment && {
        clearOnDisabled: true,
        hidden: true
      }
    })
  }
});